import * as JSOG from 'jsog'
import { all, call, put, select, StrictEffect } from 'redux-saga/effects'
import { getThreeDInputJSON } from '../../../api/3D/file-storage'
import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import {
    fetchModelStructureFailure,
    projectNumberSelector,
    updateConfigurationQuestionAndAnswersData,
    updateOpeningsToMeshIdMap,
    updateOverFramedRoofFaces,
    updateRoofFaces,
    updateStructure,
} from '../../../slices/3D'
import { availableMode, update3DTo2DMappings } from '../../../slices/common'
import { GeometryGroup, selectDrawableGroupsGeometries } from '../../../slices/geometry'
import { requestPending, sceneRendering } from '../../../slices/loading'
import {
    ConfigurationQuestionAndAnswersResponse,
    IGeometry,
    IModel,
    JoistLinesRawData,
    ModelType,
    OpeningsResponse,
    ROOF_STOREY_NAME,
    ThreeDElementsResponse,
    ThreeDToTwoDRecord,
    VIEW_MODE,
} from '../../../types'
import SagaActionEnabledError from '../../utils/sagaBaseError'
import { prepare3DTo2DMapping } from './prepare3DTo2DMapping'
import { prepareCorners } from './prepareCorners'
import { prepareGeometries } from './prepareGeometries'
import { prepareJoistLinesData } from './prepareJoistLinesData'
import { prepareJunctions } from './prepareJunctions'
import { prepareOpeningsToMeshIdMappings } from './prepareOpeningsToMeshIdMappings'
import { preparePolygons } from './preparePolygons'
import { prepareRoofEdges } from './prepareRoofEdges'

export type GeometryInputMap = Record<string, IGeometry>
export type GeometryResultMap = Record<string, IGeometry[]>

type GeometryAndStructureResponse = {
    structure: IModel
    geometries: GeometryInputMap
    roofFaces: ThreeDElementsResponse | null
    roofSingleEdges: ThreeDElementsResponse | null
    roofJunctions: ThreeDElementsResponse | null
    floorJunctions: ThreeDElementsResponse | null
    overframedRoofFaces: ThreeDElementsResponse | null
    exteriorCorners: ThreeDElementsResponse | null
    openings: OpeningsResponse | null
    configuratorQuestionsAndAnswers: ConfigurationQuestionAndAnswersResponse | null
}

// "Procedural" saga for handling the fetching and munging of data for 3d model viewing.
// Should "put" actions on the stack to populate resolved data in store
// Catch block can be used to "put" actions on the stack that populate error state in redux store
export function* prepare3DModel(): Generator<
    StrictEffect,
    void,
    string &
        GeometryAndStructureResponse &
        ThreeDToTwoDRecord &
        Record<string, string> &
        GeometryGroup[] &
        JoistLinesRawData[]
> {
    try {
        yield put(requestPending())
        const portalProjectNumber = yield select(projectNumberSelector)
        const {
            structure,
            geometries,
            roofFaces,
            overframedRoofFaces,
            exteriorCorners,
            roofSingleEdges,
            roofJunctions,
            floorJunctions,
            openings,
            configuratorQuestionsAndAnswers,
        } = yield call(getThreeDInputJSON, portalProjectNumber)

        if (structure && structure.children && geometries) {
            const drawableGroups: GeometryGroup[] = yield select(selectDrawableGroupsGeometries)
            const openingsToMeshIdMappings: Record<string, string> | null = yield call(
                prepareOpeningsToMeshIdMappings,
                JSOG.parse(JSON.stringify(openings))
            )
            const threeDToTwoDMappings: ThreeDToTwoDRecord | null = yield call(
                prepare3DTo2DMapping,
                openingsToMeshIdMappings,
                drawableGroups
            )

            const revitIdToConfigurationIDMap = yield call(ModelUtils.revitIdToConfigurationIdMap, structure)

            if (!threeDToTwoDMappings) {
                throw new Error('Error getting 2D mappings')
            }

            if (roofFaces) {
                yield call(
                    preparePolygons,
                    roofFaces,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.ROOF,
                        storeyName: ROOF_STOREY_NAME,
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap,
                    updateRoofFaces
                )
            }

            if (overframedRoofFaces) {
                yield call(
                    preparePolygons,
                    overframedRoofFaces,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.ROOF,
                        storeyName: ROOF_STOREY_NAME,
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap,
                    updateOverFramedRoofFaces
                )
            }

            if (roofSingleEdges) {
                yield call(
                    prepareRoofEdges,
                    roofSingleEdges,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.ROOF,
                        storeyName: ROOF_STOREY_NAME,
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap
                )
            }

            if (exteriorCorners) {
                yield call(
                    prepareCorners,
                    exteriorCorners,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.CORNER,
                        storeyName: '',
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap
                )
            }

            if (roofJunctions || floorJunctions) {
                yield call(
                    prepareJunctions,
                    { roofJunctions, floorJunctions },
                    threeDToTwoDMappings,
                    revitIdToConfigurationIDMap
                )
            }

            yield put(updateStructure(structure))
            yield put(updateConfigurationQuestionAndAnswersData(configuratorQuestionsAndAnswers))
            yield put(update3DTo2DMappings(threeDToTwoDMappings))
            yield put(updateOpeningsToMeshIdMap(openingsToMeshIdMappings))
            yield call(prepareGeometries, structure, geometries, threeDToTwoDMappings)
            yield call(prepareJoistLinesData, drawableGroups)
            yield all([put(availableMode(VIEW_MODE.Markup3D)), put(sceneRendering())])
        } else {
            throw new SagaActionEnabledError(fetchModelStructureFailure, 'Structure is invalid.')
        }
    } catch (error) {
        if ((error as any).actionToCall) {
            yield put((error as any).actionToCall((error as any).message))
        }
    }
}
