import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

const otherValue = 'other'

const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
        'ui:widget': 'hidden',
    },
    name: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    cornice_crown_molding: {
        'ui:title': 'Has Cornice Crown Molding?',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    folder: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    width: {
        'ui:placeholder': 'Select Eave Width (In)',
    },
    width_other: {
        'ui:placeholder': 'Other Eave Width (In)',
    },
    fascia_type: {
        'ui:placeholder': 'Select Fascia Type 1',
    },
    fascia_type_2: {
        'ui:placeholder': 'Select Fascia Type 2',
    },
    fascia_thickness_1: {
        'ui:placeholder': 'Select Fascia Thickness 1',
    },
    fascia_thickness_2: {
        'ui:placeholder': 'Select Fascia Thickness 2',
    },
    frieze_type: {
        'ui:placeholder': 'Select Frieze Type 1',
    },
    frieze_type_2: {
        'ui:placeholder': 'Select Frieze Type 2',
    },
    frieze_thickness_1: {
        'ui:placeholder': 'Select Frieze Thickness 1',
    },
    frieze_thickness_2: {
        'ui:placeholder': 'Select Frieze Thickness 2',
    },
    fc_texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    fascia_texture: {
        'ui:placeholder': 'None',
    },
    fascia_texture_2: {
        'ui:placeholder': 'None',
    },
    frieze_texture: {
        'ui:placeholder': 'None',
    },
    frieze_texture_2: {
        'ui:placeholder': 'None',
    },
    fascia_width_1: {
        'ui:placeholder': 'Select Width 1',
    },
    fascia_width_2: {
        'ui:placeholder': 'Select Width 2',
    },
    frieze_width_1: {
        'ui:placeholder': 'Select Width 1',
    },
    frieze_width_2: {
        'ui:placeholder': 'Select Width 2',
    },
    selection: {
        'ui:placeholder': 'Select Roofing',
    },
    rafter_tail: {
        'ui:placeholder': 'Select Rafter Tail',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    non_vented_soffit: {
        'ui:title': 'Non-Vented Eave',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    soffit_type: {
        'ui:placeholder': 'Select Soffit Type',
    },
    'ui:order': [
        'folder',
        'name',
        'label',
        'selection',
        'soffit_type',
        'location',
        'rafter_tail',
        'width',
        'width_other',
        'fascia_type',
        'fascia_texture',
        'fascia_thickness_1',
        'fascia_width_1',
        'fascia_type_2',
        'fascia_texture_2',
        'fascia_thickness_2',
        'fascia_width_2',
        'frieze_type',
        'frieze_texture',
        'frieze_thickness_1',
        'frieze_width_1',
        'frieze_type_2',
        'frieze_texture_2',
        'frieze_thickness_2',
        'frieze_width_2',
        'cornice_crown_molding',
        'non_vented_soffit',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (!formData.fascia_type) {
        newSettings.fascia_type = null
        newSettings.fascia_texture = null
        newSettings.fascia_width_1 = null
        newSettings.fascia_thickness_1 = null
    }

    if (!formData.fascia_type_2) {
        newSettings.fascia_type_2 = null
        newSettings.fascia_width_2 = null
        newSettings.fascia_thickness_2 = null
        newSettings.fascia_texture_2 = null
    }

    if (!formData.frieze_type) {
        newSettings.frieze_type = null
        newSettings.frieze_texture = null
        newSettings.frieze_width_1 = null
        newSettings.frieze_width_2 = null
        newSettings.frieze_thickness_1 = null
    }

    if (!formData.frieze_type_2) {
        newSettings.frieze_type_2 = null
        newSettings.frieze_width_2 = null
        newSettings.frieze_thickness_2 = null
        newSettings.frieze_texture_2 = null
    }

    if (!formData.rafter_tail) {
        newSettings.rafter_tail = null
    }

    if (!formData.selection) {
        newSettings.selection = null
    }

    if (newSettings.width === otherValue) {
        newSettings.width = newSettings.width_other
    }

    newSettings.width_other = null

    newSettings.label = !formData.label && settings.label ? null : formData.label

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
