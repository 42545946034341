import { AzureFeatureFlagIds } from '../../constants/azure-feature-flags'
import { AzureFeatureFlag } from '../../../models/azureFeatureFlags'
import isNull from 'lodash/isNull'

/**
 * By list of received azure feature flags, check if a current flag exists and if it's enabled
 *
 * @param azureFeatureFlags
 * @param azureFeatureFlagIds
 */
export const isAzureFeatureFlagEnabled = (
    azureFeatureFlags: AzureFeatureFlag[] | null,
    azureFeatureFlagIds: AzureFeatureFlagIds
): boolean => {
    if (isNull(azureFeatureFlags)) return false

    const foundAzureFeatureFlag: AzureFeatureFlag | undefined = azureFeatureFlags.find(
        (flag) => flag.id === azureFeatureFlagIds
    )

    return foundAzureFeatureFlag?.id ? foundAzureFeatureFlag.enabled : false
}
