import { getAuthorizationHeader } from '../../../authConfig'

const projectApi = process.env.REACT_APP_PORTAL_FILE_STORAGE_API

export const getTwoDMappingJSON = async (projectNumber: string): Promise<any> => {
    const fetchURL = `${projectApi}/${projectNumber}/${projectNumber}-3DGenerated.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                throw res.error
            }
            return res
        })
}

export const getThreeDInputJSON = async (projectNumber: string): Promise<any> => {
    const fetchURL = `${projectApi}/${projectNumber}/${projectNumber}-3DInput.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                throw res.error
            }
            return res
        })
}
